@import "../../assets/scss/_variables";
.contact_title {
  text-align: left;
  display: inline-block;
  text-transform: uppercase;
  .title_span {
    color: $colorOrange;
  }
}
.top_text {
  font-size: 25px;
  .links {
    color: $colorFont;
    text-decoration: underline;
  }
}
.contact_info {
  margin: 100px 0;
  .title {
    font-size: 50px;
    font-weight: bold;
    text-transform: uppercase;
    color: $colorBlue;
    margin-bottom: 50px;
  }
  .sub_title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .text {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .button {
    margin-top: 50px;
    .icon {
      margin-left: 10px;
    }
  }
  .location {
    border-right: 1px solid #ccc;
  }
}
.textarea {
  outline: none;
  border: 3px solid $colorBlue;
  font-size: 25px;
  padding: 3px 7px;
  width: 100%;
  height: 200px;
  &:focus {
    border-color: $colorOrange;
  }
  &:hover {
    border-color: $colorOrange;
  }
}
.label {
  width: 100%;
  .label_span {
    cursor: pointer;
    font-size: 25px;
    margin-bottom: 3px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact_info .location {
    padding-right: 50px;
  }
  .contact_info .title {
    font-size: 35px;
  }
  .contact_info {
    margin: 30px 0;
  }
  .top_text {
    font-size: 22px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact_info .location {
    margin-bottom: 50px;
    border-right: none;
  }
  .contact_info .title {
    font-size: 35px;
  }
  .contact_info {
    margin: 30px 0;
  }
  .top_text {
    font-size: 22px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .textarea {
    font-size: 18px;
  }
  .contact_info .location {
    margin-bottom: 50px;
    border-right: none;
  }
  .contact_info .title {
    font-size: 35px;
  }
  .contact_info {
    margin: 30px 0;
  }
  .top_text {
    font-size: 22px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .label .label_span {
    font-size: 16px;
  }
  .textarea {
    font-size: 16px;
  }
  .contact_info .button {
    margin-top: 15px;
  }
  .contact_info .text {
    font-size: 16px;
  }
  .contact_info .location {
    margin-bottom: 50px;
    border-right: none;
  }
  .contact_info .title {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .contact_info {
    margin: 0 0 30px 0;
  }
  .top_text {
    font-size: 16px;
  }
}
