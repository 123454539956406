@import "../../assets/scss/_variables";
.page {
  .hero_image_holder {
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    .info {
      position: absolute;
      top: 64%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $colorFont;
      text-align: center;
      width: 100%;
      animation: fadeIn 0.7s linear;
      .title {
        margin-bottom: 20px;
        font-size: 75px;
        font-weight: bold;
        color: $colorFont;
      }
      .text {
        font-size: 18px;
        width: 600px;
        text-align: center;
        margin: 0 auto 30px auto;
      }
      .links {
        color: #fff;
        background-color: $colorOrange;
        border-color: $colorOrange;
        cursor: pointer;
        &:hover {
          background-color: #cc0000;
          border-color: #cc0000;
        }
        .icon {
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
    .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(27, 44, 99, 0.69);
    }
    // &:before {
    //   content: "";
    //   display: block;
    //   width: 110%;
    //   height: 210px;
    //   background-color: #fff;
    //   position: absolute;
    //   bottom: -100px;
    //   left: -100px;
    //   transform: rotate(5deg);
    //   box-shadow: 8px 1px 8px 1px #000;
    //   z-index: 1;
    // }
    // &:after {
    //   content: "";
    //   display: block;
    //   width: 110%;
    //   height: 210px;
    //   background-color: #fff;
    //   position: absolute;
    //   bottom: -100px;
    //   left: -100px;
    //   transform: rotate(-5deg);
    //   box-shadow: 8px 1px 8px 1px #949494;
    //   z-index: 2;
    // }
  }
}
.form_holder {
  padding: 100px 0;
  .title {
    font-size: 70px;
    text-align: center;
    margin-bottom: 100px;
  }
  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &.checkbox {
      flex-direction: row;
      align-items: center;
      .label_span {
        margin-left: 15px;
        max-height: 120px;
        overflow-y: scroll;
        /* width */
        &::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $colorBlue;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .links {
          color: red;
        }
      }
    }
    .label_span {
      font-size: 25px;
      margin-bottom: 3px;
      &.legal {
        font-size: 16px;
      }
    }
  }
  .textarea {
    outline: none;
    border: 3px solid $colorBlue;
    font-size: 25px;
    padding: 3px 7px;
    width: 100%;
    height: 200px;
    &:focus {
      border-color: $colorOrange;
    }
    &:hover {
      border-color: $colorOrange;
    }
  }
  .button {
    width: 100%;
  }
  .text {
    font-size: 25px;
    margin-top: 30px;
    span {
      color: $colorOrange;
      font-weight: bold;
    }
  }
}
.open_positions{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  // grid-template-columns: auto auto;
  // grid-auto-flow: column;
  // width: 450px;
  grid-column-gap: 20px;
  grid-row-gap:20px;
  .link{
    &:hover{
      text-decoration: none;
    }
  }
  .box{
    border: 1px solid $colorBlue;
    border-top: 3px solid $colorBlue;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0,  0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: #fff;
    .job_title{
      font-size: 18px;
      letter-spacing: 1px;
      height: 44px;
      color: $colorOrange;
 
      font-weight: bold;
      margin-bottom: 20px;
    }
    .button{
      height: auto;
      min-width: auto;
      width: auto;
      display: inline;
      padding: 7px 20px;
      font-size: 16px;
     
    }
  }
}
.open_pos_title{
  margin-bottom: 30px;
  color: $colorBlue;
  font-size: 40px;
}
.puzzle_holder{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .hover_holder{
    position: relative;
    width: 300px;
    height: 300px;
    .puzzle_img{
      position: absolute;
      transition: all ease-in-out 0.5s;
      &.blue{
        left:-130px;
        bottom:-70px;
        transform: rotate(-53deg);
        width: 150px;
        &.animate{
          left: 0;
          bottom: 0;
          transform: none;
        }
      }
      &.light_blue{
        right: -110px;
        transform: rotate(23deg);
        width: 151px;
        &.animate{
          right: 1px;
          top:0px;
          transform: none;
        }
      }
      &.red{
        right: 0;
        bottom: -55px;
        height: 150px;
        &.animate{
          right: 0;
          bottom: 0;
        }
      }
      &.gray{
        left: -10px;
        transform: rotate(12deg);
        height: 150px;
        &.animate{
          left: 0;
          transform: none;
        }

      }
      &.group{
        width: 300px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    top:100%;
  }
  100% {
    top:64%;
    opacity: 1;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .form_holder .label .label_span {
    font-size: 20px;
  }
  .form_holder .text {
    font-size: 22px;
  }
  .form_holder .title {
    margin-bottom: 65px;
    font-size: 50px;
  }
  .form_holder {
    padding: 0 0 50px 0;
  }
  .page .hero_image_holder:after {
    width: 115%;
  }
  .page .hero_image_holder .info .title {
    font-size: 90px;
    margin-bottom: 10px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  @keyframes fadeIn {
    0% {
      opacity: 0;
      top:100%;
    }
    100% {
      top:70%;
      opacity: 1;
    }
  }
  .page .hero_image_holder .info{
    top:70%;
  }
  .puzzle_holder{
    margin-top: 60px;
  }
  .page .hero_image_holder .info .text {
    padding: 0 30px;
  }
 
  .form_holder .label .label_span {
    font-size: 20px;
  }
  .form_holder .text {
    font-size: 22px;
    margin-top: 60px;
  }
  .form_holder .title {
    margin-bottom: 65px;
    font-size: 50px;
  }
  .form_holder {
    padding: 0 0 50px 0;
  }
  .page .hero_image_holder:after {
    width: 115%;
  }
  .page .hero_image_holder .info .title {
    font-size: 70px;
    margin-bottom: 10px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  @keyframes fadeIn {
    0% {
      opacity: 0;
      top:100%;
    }
    100% {
      top:70%;
      opacity: 1;
    }
  }
  .page .hero_image_holder .info{
    top:70%;
  }
  .puzzle_holder{
    margin-top: 60px;
  }
  .open_positions{
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 360px;
  }
  .open_pos_title{
    font-size: 25px;
  }
  .form_holder .textarea {
    font-size: 20px;
  }
  
  .page .hero_image_holder .info .text {
    padding: 0 30px;
    font-size: 18px;
  }
  .form_holder .label .label_span {
    font-size: 18px;
  }
  .form_holder .text {
    font-size: 20px;
    margin-top: 60px;
    width: auto;
  }
  .form_holder .title {
    margin-bottom: 65px;
    font-size: 45px;
  }
  .form_holder {
    padding: 0 0 50px 0;
  }
  .page .hero_image_holder:after {
    width: 155%;
  }
  .page .hero_image_holder .info .title {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .puzzle_holder .hover_holder .puzzle_img.light_blue.animate{
    top:1px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      top:75%;
    }
    100% {
      top:55%;
      opacity: 1;
    }
  }
  .page .hero_image_holder .info{
    top:55%;
  }
  .puzzle_holder{
    margin-top: 65px;
  }
  .puzzle_holder .hover_holder .puzzle_img.blue{
    width:  100px;
  }
  .puzzle_holder .hover_holder .puzzle_img.light_blue{
    width: 100px;
  }
  .puzzle_holder .hover_holder .puzzle_img.red{
    height: 100px;
  }
  .puzzle_holder .hover_holder .puzzle_img.gray{
    height: 100px;
  }
  .puzzle_holder .hover_holder{
    width: 200px;
    height: 200px;
  }
  .open_positions{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .open_pos_title{
    font-size: 25px;
  }
  .form_holder .textarea {
    font-size: 16px;
  }
  
  .page .hero_image_holder .info .text {
    padding: 0 30px;
    font-size: 16px;
    width: auto;
  }
  .form_holder .label .label_span {
    font-size: 16px;
  }
  .form_holder .text {
    font-size: 18px;
    margin-top: 60px;
    &:last-child{
      margin-top: 20px;
    }
  }
  .form_holder .title {
    margin-bottom: 35px;
    font-size: 35px;
  }
  .form_holder {
    padding: 0 0 50px 0;
  }
  .page .hero_image_holder:after {
    width: 155%;
  }
  .page .hero_image_holder .info .title {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
