@import "../../assets/scss/_variables";
.page{
    padding: 80px 0;
    // background-image: url("../../assets/images/Polygon\ Luminary.svg");
    position: relative;
    .bckg_image{
        position: fixed;
        right: 0;
        bottom: 0;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.07;
    }
}
.desc_title{
    color:$colorBlue;
    font-weight: bold;
    margin-top: 25px;
    font-size: 45px;
    position: relative;
    display: inline-block;
    &::after{
        content: "";
        width: 40%;
        height: 4px;
        background-color: $colorOrange;
        display: block;
        margin-top: 10px;
    }
}
.subtitle{
  font-size: 20px;
  margin-bottom: 15px;
  display: block;
  color: $colorOrange;
}
.desc_text{
    font-size: 16px;

}
.desc_list{
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 20px!important;
    .items{
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.2;
    }
}
.form_holder {
    padding: 25px 25px;
    color: #333;
    position: relative;
    width: 900px;
    margin: 0 auto;
    .intro_text{
      color: #333;
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
    }
    .label {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      &.checkbox {
        flex-direction: row;
        align-items: center;
        border: 1px solid #ebebeb;
        padding: 10px;
        
        .label_span {
          margin-left: 15px;
          max-height: 120px;
          overflow-y: scroll;
          /* width */
          &::-webkit-scrollbar {
            width: 7px;
          }
  
          /* Track */
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
  
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #555;
          }
  
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
          .links {
            color: red;
          }
        }
      }
      .label_span {
        font-size: 25px;
        margin-bottom: 3px;
        &.legal {
          font-size: 16px;
        }
      }
    }
    .textarea {
      outline: none;
      border: 3px solid $colorBlue;
      font-size: 25px;
      padding: 3px 7px;
      width: 100%;
      height: 200px;
      &:focus {
        border-color: $colorOrange;
      }
      &:hover {
        border-color: $colorOrange;
      }
    }
    .button {
      width: 40%;
      border-color: $colorOrange;
      background-color: $colorOrange;
      display: block;
      margin: 0 auto;
      &:hover{
        background-color: #cc0000;
      }
    }
    .text {
      font-size: 25px;
      margin-top: 30px;
      span {
        color: $colorOrange;
        font-weight: bold;
      }
    }
}
.intro_text{
    font-size: 16px;
    color: #000;
    opacity: 0.7;
    line-height: 1.40;
    b{
      opacity: 1 !important;
      color: $colorBlue;
    }
}
.divider{
  height: 100%;
  width: 2px;
  background-color: #ccc;
  // opacity: 0.3;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.job_description_holder{
  width: 1200px;
  margin: 0 auto;
}
.apply_btn_holder{
  margin: 50px auto 50px auto;
  display: block;
  text-align: center;
  .apply_btn{
    background-color: $colorOrange;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    &:hover{
      text-decoration: none;
      background-color: #cc0000;
    }
  }
}
.apply_form_page{
  padding: 20px 0;
}
.job_title{
  font-size: 55px;
  color: $colorBlue;
  margin-bottom: 30px;
  &::after{
    content: "";
    width: 20%;
    height: 4px;
    background-color: $colorOrange;
    display: block;
    margin-top: 10px;
}
  
}

.back_arrow_holder{
  margin-bottom: 40px;
  .back_arrow_link{
    color: #333;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: $colorOrange;
    .icon{
      margin-right: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}



@media (min-width: 1400px) and (max-width: 1499.98px) {
}
@media (min-width: 1300px) and (max-width: 1399.98px) {
  .job_title{
    font-size: 45px;
  }
  .desc_title{
    font-size: 35px;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px) {
  .form_holder .label .label_span{
    font-size: 20px;
  }
  .label .label_span{
    font-size: 20px;
  }
  .job_title{
    font-size: 35px;
  }
  .job_description_holder{
    width: 100%;
  }
  .desc_title{
    font-size: 35px;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .job_description_holder{
    width: 100%;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .form_holder .button{
    font-size: 18px;
  }
  .job_title{
    font-size: 35px;
  }
  .apply_btn_holder{
    margin: 20px 0 40px 0;
  }
  .job_description_holder{
    width: 100%;
  }
  .form_holder{
    width: 100%;
  }
  .page{
    padding: 50px 0;
  }
  .desc_title{
    margin-bottom: 20px;
    font-size: 30px;
  }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .job_title{
    font-size: 30px;
  }
  .form_holder .button{
    width: 100%;
  }
  .job_description_holder{
    width: 100%;
  }
  .form_holder{
    width: 100%;
  }
  .page{
    padding: 50px 0;
  }
  .desc_title{
    margin-bottom: 20px;
    font-size: 30px;
  }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .form_holder .label .label_span{
    font-size: 16px;
  }
  .form_holder .button{
    width: 100%;
  }
  .job_title{
    font-size: 30px;
  }
  .job_description_holder{
    width: 100%;
  }
  .desc_list .items{
    line-height: 1.3;
  }
  .intro_text{
    line-height: 1.1;
  }
  .form_holder{
    width: 100%;
    padding: 25px 0px;
  }
  .page{
    padding: 30px 0;
  }
  .desc_title{
    margin-bottom: 20px;
    font-size: 25px;
  }
}
