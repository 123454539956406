@import "../../assets/scss/_variables";
.modal{

}
.text{
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 20px;
    a{
        color:$colorOrange;
    }
}
.cookies_type{
    margin: 20px 0;
    padding: 10px 30px;
    border: 1px solid #eee;
    border-left: none;
    border-right: none;
    .check_holder{
        display: flex;
        .details{
            margin-left: 15px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
.details_dropdown{

}
.pref_modal_btn_holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.save_btn{
    background-color: #1b2c63;
    border: 2px solid #1b2c63;
    height: auto;
    min-width: auto;
    &:hover{
        color: #fff;
        text-decoration: none;
        background-color: #35467d;
        border-color: #35467d;
    }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .cookies_type{
        margin: 10px 0;
        padding: 10px 10px;  
    }
}
