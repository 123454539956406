@import "../../assets/scss/variables";
.footer {
  background-color: #f7f7f7;
  padding: 50px 0;
  .left_part {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .logo {
     margin-bottom: 20px;
    }
    .menu {
 
      .list {
    
        .items {
          display: inline-block;
          margin-right: 20px;
          &:first-child{
            margin-left: 0;
          }
          .links {
            color: $colorFont;
          }
        }
      }
    }
  }
  .right_part {
    text-align: right;
    .social_list {
      .items {
        margin-bottom: 20px;
        .links {
          .icon {
            font-size: 28px;
            color: $colorBlue;
          }
        }
      }
    }
    .privacy_list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .items {
        margin: 30px 0 15px 0;
        margin-left: 20px;
        .links {
          color: #333;
        }
      }
    }
    .copyright_list {
      .items {
        color: #8c8e92;
        .freepik {
          margin-top: 20px;
          display: block;
          font-size: 14px;
          color: #8c8e92;
        }
      }
    }
  }
}

.footer_contact{
  background-color: #1d1c1c;
  background-color:#f7f7f7;
  border-top: 1px solid rgba(204 ,204 ,204, 0.18);
  border-bottom: 1px solid rgba(204 ,204 ,204, 0.18);
  .list{
    width: 100%;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    .items{
      padding: 50px 40px;
     display: flex;
     align-items: center;
     justify-content: center;
      border-right: 1px solid rgba(204 ,204 ,204, 0.18);
      border-left: 1px solid rgba(204 ,204 ,204, 0.18);
      height: 100px;
      &:first-child{
        border-left: 2px solid rgba(204 ,204 ,204, 0.18);
      }
      &:last-child{
        border-right: 2px solid rgba(204 ,204 ,204, 0.18);
      }
      .links{
        display: flex;
        align-items: center;
        color:$colorFont;
        &:hover{
          text-decoration: none;
        }
        span{
          font-size:15px;
          line-height: 1.2;
          color: $colorFont;
        }
        .icon{
          margin-right: 15px;
          width: 50px;
        }
      }
    }
  }
}

@media (min-device-width: 1400px) and (max-device-width: 1499.98px) {

}
@media (min-device-width: 1300px) and (max-device-width: 1399.98px) {
  .footer_contact .list .items{
    height: 90px;
    padding: 0 40px;
  }
  .footer_contact .list .items .links span{
    font-size: 13px;
  }

}
@media (min-device-width: 1200px) and (max-device-width: 1299.98px) {
  .footer_contact .list .items{
    height: 90px;
    padding: 0 40px;
  }
  .footer_contact .list .items .links span{
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer .left_part .menu .list .items{
    margin-bottom: 10px;
  }
  .footer .left_part .menu .logo{
    width: 210px;
  }
  .footer_contact .list .items{
    height: 90px;
    padding: 0 10px;
  }
  .footer_contact .list .items .links span{
    font-size: 13px;
  }
  .footer_contact .list .items .links .icon{
    width: 40px;
  }
  .footer_contact .list .items:first-child{
    border-left: none;
  }
  .footer_contact .list .items:last-child{
    border-right: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .footer .right_part .privacy_list{
    justify-content: center;
  }
  .footer_contact .list .items:first-child{
    border: none;
  }
  .footer_contact .list .items:last-child{
    border: none;
  }
  .footer_contact .list .items{
    height: 120px;
    text-align: center;
    padding: 10px 5px;
  }
  .footer_contact .list .items .links span{
    font-size: 12px;
  }
  .footer_contact .list .items .links .icon{
    width: 28px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .footer_contact .list .items .links{
    flex-direction: column;
  }
  .footer .right_part {
    text-align: center;
    margin-top: 30px;
  }
  .footer .left_part {
    justify-content: center;
  }
}

@media (max-width: 575.98px) {
  .footer .right_part .privacy_list{
    justify-content: center;
    .items{
      margin-left: 0;
      margin-right: 20px;
    }
  }
  .footer{
    padding: 30px 0;
  }
  .footer_contact .list{
    grid-auto-flow: row;
    padding: 30px 0;
  }
  .footer_contact .list .items .links span{
    font-size: 14px;
    text-decoration: underline;
  }
  .footer_contact .list .items .links .icon{
    width: 28px;
    margin-right: 10px;
    margin-bottom: 0;

  }
  .footer_contact .list .items{
    margin-bottom: 30px;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    border: none;
    height: auto;

  }
  .footer_contact .list .items:first-child{
    border: none;
   
  }
  .footer_contact .list .items:last-child{
    border: none;
    margin-bottom: 0;
  }
  .footer .left_part .menu .list .items {
    margin-right: 25px;
    margin-bottom: 15px;
  }
  .footer .right_part {
    text-align: center;
    margin-top: 20px;
  }
  .footer .left_part {
    justify-content: center;
    .logo{
      width: 180px;
    }
  }
}
