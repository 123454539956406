@import "_variables";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  &.scroll_hidden {
    overflow: hidden;
  }
}
#root{
  overflow-x: hidden;
}
// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

ul {
  list-style: none;
  padding: 0;
  margin: 0 !important;
  margin: 0;
}
.menu_link_active {
  position: relative !important;
  &:after {
    content: "" !important;
    position: absolute !important;
    bottom: -10px !important;
    left: 0 !important;
    display: block !important;
    width: 100% !important;
    height: 2px !important;
    background-color: $colorOrange !important;
    margin: 6px auto 0 auto !important;
    transition: all ease-in 0.2s !important;
  }
}
.section_title {
  font-size: 65px;
  color: $colorBlue;
  font-weight: bold;
  margin-bottom: 60px;
  text-align: center;
  position: relative;
  &.pages {
    font-size: 80px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 95px;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    width: 100px;
    height: 5px;
    background-color: $colorOrange;
  }
}
.ns_button {
  background-color: $colorBlue;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: unset;
  text-align: center;
  border: 2px solid $colorBlue;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  height: 54px;
  min-width: 196px;
  padding: 16px 18px 18px;
  font-size: 20px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.25px;
  position: relative;
  display: inline-block;
  transition: all ease 0.2s;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #35467d;
  }
}
.page_header {
  padding: 150px 0 0 0;
}
.label_reversed {
  flex-direction: row-reverse !important;
}
.page_graphic_image {
  opacity: 0.4;
  position: absolute;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d14638;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.swal2-title {
  color: #fff !important;
}
.swal2-html-container {
  color: #fff !important;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #000 !important;
}
.swal2-icon.swal2-error {
  border-color: #000 !important;
}
.pref_modal{
  .modal-footer{
    border: none;
  }
  .pref_table{
    font-size: 12px;
  }
}

 /* the slides */
 .slick-track{
    display: flex !important;
 }
 .slick-slide {
  margin: 0 27px;
  height: inherit !important;
  display: flex !important; 	
  justify-content: center; 	
  align-items: center;
}

/* the parent */
.slick-list {
  margin: 0 -27px;
}
.slick-prev, .slick-next{
  z-index: 999!important;
}
.slick-prev {
  left: -25px;
}
.slick-next{
  right: -25px!important;
}
.slick-prev:before, .slick-next:before{
  color: #000!important;
  font-size: 50px!important;

}

.hideCookies{
  display: none!important;
}


@media all and (min-width: 1400px) {
  .container {
    max-width: 1360px !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section_title.pages {
    font-size: 70px;
  }
  .page_header {
    padding: 90px 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .section_title:after{
    top: 55px;
  }
  .section_title{
    font-size: 40px;
  }
  .section_title.pages {
    font-size: 65px;
  }
  .page_header {
    padding: 60px 0;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .section_title:after{
    top: 40px;
  }
  .section_title{
    font-size: 40px;
  }
  .cookies_text {
    font-size: 14px;
  }
  .section_title {
    &:after {
      top: 45px;
    }
  }
  .section_title.pages {
    font-size: 45px;
  }
  .page_header {
    padding: 50px 0 25px 0;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .pref_modal{
    margin-top: 50px;
    max-height: 550px;
    overflow-y: scroll;
     .modal-footer{
       padding: 2px !important;
     }
  }
  .cookies_text {
    font-size: 12px;
  }
  .ns_button {
    height: 55px;
    font-size: 16px;
    min-width: 150px;
  }
  .section_title {
    font-size: 35px;
    margin-bottom: 15px;
    &:after {
      top: 45px;
    }
  }
  .section_title.pages {
    font-size: 40px;
    &:after {
      top: 50px;
    }
  }
  .page_header {
    padding: 50px 0;
  }
}
