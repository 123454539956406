@import "../../../assets/scss/variables";
.partner_story {
  // padding: 100px 0;
  padding: 60px 0 100px 0;
  position: relative;

  .graph_image_circle {
    top: 350px;
    left: 90px;
    width: 300px;
  }
  .graph_image_triangle {
    bottom: -120px;
    right: 130px;
    width: 300px;
  }
  .sup_title {
    font-size: 20px;
    font-weight: bold;
    color: #ccc;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .title {
    font-size: 60px;
    width: 540px;
  }
  .text {
    font-size: 20px;
    line-height: 1.4;
    margin-top: 30px;
    margin-bottom: 70px;
    width: 440px;
  }
  .links {
    font-size: 22px;
    color: $colorFont;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: $colorBlue;
      .icon {
        margin-left: 10px;
      }
    }
    .icon {
      transition: all ease-in-out 0.4s;
      font-size: 22px;
      font-weight: bold;
    }
  }
}



@media only screen and (min-device-width: 1400px) and (max-device-width: 1499px) {
  .partner_story .links{
    font-size: 18px;
  }
  .partner_story .title{
    font-size: 48px;
  }
  .partner_story .image{
    width: 82%;
  }

}

@media only screen and (min-device-width: 1300px) and (max-device-width: 1399px) {
  .partner_story .links{
    font-size: 18px;
  }
  .partner_story .title{
    font-size: 48px;
  }
}

@media (min-device-width: 1200px) and (max-device-width: 1299.98px) {
  .partner_story .links{
    font-size: 18px;
  }
  .partner_story .title{
    font-size: 48px;
  }
  .partner_story .image{
    width: 85%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .partner_story .links {
    font-size: 18px;
  }
  .partner_story .graph_image_circle {
    width: 150px;
  }
  .partner_story .graph_image_triangle {
    width: 150px;
  }
  .partner_story .title {
    font-size: 45px;
  }
  .partner_story {
    padding: 50px 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .partner_story .image {
    width: 435px;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .partner_story .links {
    font-size: 18px;
  }
  .partner_story .graph_image_circle {
    width: 150px;
  }
  .partner_story .graph_image_triangle {
    width: 150px;
  }
  .partner_story .title {
    font-size: 35px;
    width: 100%;
  }
  .partner_story {
    padding: 60px 0 60px 0;
  }
  .partner_story .text{
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .partner_story .image {
    width: 400px;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .partner_story .links {
    font-size: 18px;
  }
  .partner_story .graph_image_circle {
    width: 150px;
  }
  .partner_story .graph_image_triangle {
    width: 150px;
    bottom: -80px;
    right: 45px;
  }
  .partner_story .title {
    font-size: 35px;
    width: 100%;
  }
  .partner_story {
    padding: 50px 0;
  }
}

@media (max-width: 575.98px) {
  .partner_story .text {
    font-size: 16px;
    width: auto;
    margin-bottom: 35px;
  }
  .partner_story .sup_title {
    font-size: 16px;
  }
  .partner_story .image {
    width: 300px;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
  .partner_story .links {
    font-size: 16px;
   
  }
  .partner_story .graph_image_circle {
    width: 150px;
  }
  .partner_story .graph_image_triangle {
    width: 150px;
    bottom: -80px;
    right: 45px;
  }
  .partner_story .title {
    font-size: 25px;
    width: 100%;
  }
  .partner_story {
    padding: 40px 0;
  }
}
