@import "../../../assets/scss/variables";
.contact_holder {
  padding: 150px 0;
  background-color: #fff;
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      border-radius: 10px;
      border: 1px solid #d4d4d4;
      padding: 20px;
      width: 350px;
      height: 230px;
      position: relative;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      background-color: #1b2c63;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .links {
        display: block;
        height: 100%;
        width: 100%;
        .icon {
          font-size: 110px;
          color: $colorOrange;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          &.linkedin {
            color: #0a66c2;
          }
          &.email {
            color: #ff9b42;
          }
        }
        .name {
          color: #fff;
          font-size: 23px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          &.address {
            font-size: 18px;
            padding: 0 10px;
            width: 100%;
          }
        }
      }
    }
  }
}
.contact_link_holder {
  padding: 100px 0;
  background-color: $colorGray;
  position: relative;
  .graph_image_triangle {
    top: 0;
    right: 120px;
    width: 200px;
    transform: translate(0, -50%);
    opacity: 1;
    z-index: 9;
  }
  .bckg_image {
    position: absolute;
    right: 100px;
    top: 0;
    height: 100%;
    opacity: 0.3;
    width: 820px;
  }
  .title {
    font-size: 55px;
    &::after {
      content: "";
      display: block;
      width: 250px;
      height: 5px;
      margin-top: 20px;
      background-color: $colorOrange;
    }
  }
  .text {
    font-size: 20px;
    margin: 30px 0 60px 0;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact_link_holder .title {
    font-size: 45px;
  }
  .contact_link_holder .graph_image_triangle {
    width: 115px;
  }
  .contact_holder .info .box .links .icon {
    width: 70px;
  }
  .contact_holder .info .box .links .name {
    font-size: 20px;
  }
  .contact_holder {
    padding: 100px 0;
  }
  .contact_holder .info .box {
    width: 280px;
    height: 190px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .contact_link_holder .title {
    font-size: 45px;
  }
  .contact_link_holder .graph_image_triangle {
    width: 115px;
  }
  .contact_holder .info .box .links .icon {
    width: 55px;
  }
  .contact_holder .info .box .links .name {
    font-size: 16px;
    width: 100%;
    line-height: 1.2;
  }
  .contact_holder {
    padding: 100px 0;
  }
  .contact_holder .info .box {
    width: 220px;
    height: 160px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .contact_link_holder .title {
    font-size: 35px;
  }
  .contact_link_holder .graph_image_triangle {
    width: 115px;
    right: 40px;
  }
  .contact_holder .info .box .links .icon {
    width: 70px;
  }
  .contact_holder .info .box {
    margin-bottom: 60px;
    margin-right: 0;
  }
  .contact_holder .info {
    flex-direction: column;
  }
  .contact_holder {
    padding: 70px 0;
  }
}

@media (max-width: 575.98px) {
  .contact_link_holder .text {
    font-size: 16px;
  }
  .contact_link_holder .title::after {
    width: 125px;
  }
  .contact_link_holder {
    padding: 50px 0;
  }
  .contact_holder .info .box .links .name.address {
    font-size: 15px;
  }
  .contact_holder .info .box .links .name {
    font-size: 18px;
  }
  .contact_link_holder .bckg_image {
    width: 100%;
    right: 0;
  }
  .contact_link_holder .title {
    font-size: 25px;
  }
  .contact_link_holder .graph_image_triangle {
    width: 115px;
  }
  .contact_holder .info .box .links .icon {
    width: 55px;
  }
  .contact_holder .info .box {
    margin-bottom: 60px;
    width: 100%;
    height: 145px;
    margin-right: 0;
  }
  .contact_holder .info {
    flex-direction: column;
  }
  .contact_holder {
    padding: 30px 0;
  }
}
