$colorBlue: #1b2c63;
$colorGray: #f7f7f7;
$colorBlack: #1d1c1c;
$colorOrange: #ff331f;

$colorFont: rgba(0, 0, 0, 0.85);

// green #419D78
// pink #EE4B6A
// orange #ff9b42

$font: montserrat;
$fontBold: montserrat_bold;
$fontBlack: montserrat_black;
