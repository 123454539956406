@import "../../assets/scss/_variables";
.header{
  padding: 20px 0;
}
.page {
  padding-bottom: 100px;
  background-image: url("../../assets/images/backg2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .title{
    font-size: 65px;
    span{
      color: $colorOrange;
    }
  }
}
.main_title {
  font-size: 40px;
  font-style: italic;
  font-weight: bold;
  margin-top: 150px;
}
.title_author {
  margin-bottom: 120px;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
}
.text {
  font-size: 20px;
  b {
    color: #ff331f;
  }
}
.page_image {
  margin: 30px 0;
}
.sub_title {
  font-size: 40px;
  color: $colorBlue;
  font-weight: bold;
  margin-bottom: 60px;
  text-align: left;
  position: relative;
  display: inline-block;
  margin: 40px 0;
  &:after {
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    display: block;
    width: 80px;
    height: 5px;
    background-color: $colorOrange;
  }
}

.button_holder {
  padding: 100px 0;
  text-align: center;
}
.values_box_holder{
  display: grid;
  grid-template-columns: auto auto ;
  grid-gap: 30px;
  .values_box{
    background-color: #fff;
    padding: 50px 40px;
    border: 1px solid #eee;
    border-top: 5px solid $colorBlue;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translate(-50%,-50%);
      z-index: 0;
      opacity: 0;
      background-color: $colorBlue;
      transition: all ease-in-out 0.3s;
      z-index: 0;
    }
    &:hover{
     border-color: transparent;
      &::before{
       opacity: 1;
        width: 100%;
        height: 100%;
      }
      .title{
        color: #fff;
      }
      .text{
        color: #fff;
      }
      .values_icon{
        filter: contrast(0.5);
      }
    }
    .title{
      font-size: 16px;
      text-transform: uppercase;
      position: relative;
      transition: all linear 0.5s;
      margin-bottom: 30px;
      height: 40px;
    }
    .text{
      font-size: 16px;
      line-height: 1.7;
      position: relative;
      transition: all linear 0.5s;
      height: 190px;
    }
    .values_icon{
      width: 80px;
      position: relative;

    }
  }
}
.our_values_title{
  margin-bottom: 20px;
  font-size: 30px;
  color: $colorBlue;
}
.mission{
  padding: 80px 0;
  .m_row{
    margin: 40px 0;
  }
  .box{
    display: flex;
    align-items: center;
    .icon_holder{
      width: 70px;
      margin-right: 30px;
    }
    .text_holder{
      width: 500px;
      .text {
        font-size: 18px;
        b {
          color: #ff331f;
        }
      }
    }
  }
  .line_holder{
    width: 100%;
    // height: 175px;
    // border: 1px solid red;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    &.reversed{
      justify-content: flex-end;
      padding-right: 60px;
      .line{
        border-right: none;
        border-left: 3px solid $colorBlue;
        border-top-right-radius: 0;
        border-top-left-radius: 100px;
      }
    }
    .line{
      width: 400px;
      height: 100px;
      border: none;
      border-top: 3px solid $colorBlue;
      border-right: 3px solid $colorBlue;
      border-top-right-radius: 100px;
    }
  }
 
}
.arrow_icon{
  width: 200px;
  transform: rotate(60deg);
  opacity: 0.6;
  &.reversed{
    transform: scaleX(-1) rotate(60deg);
  }
}
.header_image{
  width: 75%;
  border-radius: 20px;
  margin: 0 auto;
  display: block;
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .values_box .icon {
    width: 65px;
  }
  .values_box .text {
    font-size: 20px;
  }
  .button_holder {
    padding: 60px 0 0 0;
  }
  .page {
    padding-bottom: 0;
  }
  .sub_title {
    font-size: 35px;
    &:after {
      top: 45px;
    }
  }
  .text {
    font-size: 18px;
  }
  .main_title {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 50px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .header_image{
    width: 70%;
  }
  .title_author{
    margin-bottom: 40px;
  }
  .values_box_holder .values_box .text{
    height: auto;
    margin-bottom: 20px;
  }
  .values_box .icon {
    width: 60px;
  }
  .values_box .text {
    font-size: 18px;
  }
  .button_holder {
    padding: 60px 0 0 0;
  }
  .page {
    padding-bottom: 0;
  }
  .sub_title {
    font-size: 35px;
    &:after {
      top: 45px;
    }
  }
  .text {
    font-size: 18px;
    line-height: 1.3;
  }
  .main_title {
    margin-top: 30px;
    margin-bottom: 30px;
    // font-size: 22px;
  }
  .page .title{
    font-size: 40px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  
  .page .title{
    font-size: 40px;
  }
  .values_box_holder .values_box .values_icon{
    width: 40px;
  }
  .values_box_holder .values_box .title{
    font-size: 14px;
    height: 52px;
    margin-bottom: 15px;

  }
  .values_box_holder .values_box{
    
    padding: 20px 15px;
  }
  .values_box_holder .values_box .text{
    height: auto;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;
  }
  .values_box .icon {
    width: 60px;
  }
  .values_box .text {
    font-size: 18px;
  }
  .mission .box .icon_holder{
    width: 135px;
  }
  .mission .box .text_holder{
    width: auto;
    h3{
      font-size: 22px;
    }
  }
  .mission .box .text_holder .text{
    font-size: 16px;
    line-height: 1.2;
  }
  .mission{
    padding: 40px 0;
  }
  .title_author{
    margin-bottom: 40px;
  }
  .values_box .icon {
    width: 60px;
  }
  .values_box .text {
    font-size: 18px;
  }
  .button_holder {
    padding: 60px 0 0 0;
  }
  .page {
    padding-bottom: 0;
  }
  .sub_title {
    font-size: 30px;
    &:after {
      top: 45px;
    }
  }
  .text {
    font-size: 18px;
  }
  .main_title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 28px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .header_image{
    width: 100%;
  }
  .page .title{
    font-size: 40px;
  }
  .values_box_holder{
    grid-template-columns: auto;
  }
  .values_box_holder .values_box .values_icon{
    width: 40px;
  }
  .values_box_holder .values_box .title{
    font-size: 16px;
    height: auto;
    margin-bottom: 15px;

  }
  .values_box_holder .values_box{
    padding: 20px 15px;
  }
  .values_box_holder .values_box .text{
    height: auto;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;
  }
  .values_box .icon {
    width: 60px;
  }
  .values_box .text {
    font-size: 18px;
  }
  .mission .box .icon_holder{
    width: 135px;
  }
  .mission .box .text_holder{
    width: auto;
    h3{
      font-size: 22px;
    }
  }
  .mission .box .text_holder .text{
    font-size: 16px;
    line-height: 1.2;
  }
  .mission{
    padding: 40px 0;
  }
  .title_author{
   font-size: 16px;
    margin-bottom: 20px;
  }
  .values_box .icon {
    width: 60px;
  }
  .values_box .text {
    font-size: 18px;
  }
  .button_holder {
    padding: 60px 0 0 0;
  }
  .page {
    padding-bottom: 0;
  }
  .sub_title {
    font-size: 26px;
    &:after {
      top: 37px;
    }
  }
  .text {
    font-size: 16px;
  }
  .main_title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;
  }
}
