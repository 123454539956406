.page{
    min-height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text_holder{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 390px;
        .num{
            font-size: 25px;
        }
        .separator{
            &::after{
                content: "";
                display: block;
                width: 1px;
                height: 50px;
                background-color: #8b8b8b;
            }
        }
        .text{
            font-size: 25px;
            letter-spacing: -1px;
        }
    }
}
@media (min-width: 1400px) and (max-width:1499.98px){}
@media (min-width: 1300px) and (max-width:1399.98px){
    .page{
        min-height: 500px;
    }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px ) and (max-width:1299.98px) {
    .page{
        min-height: 670px;
    }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .page{
        min-height: 70vh;
    }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .page{
        min-height: 70vh;
    }
    .page .text_holder{
        width: 300px;
        .num{
            font-size: 20px;
        }
        .separator{
           &::after{
            height: 35px;
           }
        }
        .text{
            font-size: 20px;
        }
    }
}
