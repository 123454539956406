@import "../../assets/scss/_variables";
.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .label_span {
    cursor: pointer;
    font-size: 25px;
    margin-bottom: 3px;
  }
  .field {
    width: 100%;
    outline: none;
    border: 3px solid $colorBlue;
    font-size: 25px;
    padding: 3px 7px;
    &:focus {
      border-color: $colorOrange;
    }
    &:hover {
      border-color: $colorOrange;
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .label .label_span {
    font-size: 20px;
  }
  .label {
    margin-bottom: 20px;
  }
  .label .field {
    font-size: 20px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .label .label_span {
    font-size: 20px;
  }
  .label {
    margin-bottom: 20px;
  }
  .label .field {
    font-size: 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .label .label_span {
    font-size: 20px;
  }
  .label {
    margin-bottom: 20px;
  }
  .label .field {
    font-size: 18px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .label .label_span {
    font-size: 16px;
  }

  .label {
    margin-bottom: 20px;
  }
  .label .field {
    font-size: 16px;
  }
}
