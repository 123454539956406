
@font-face {
  font-family: lato, sans-serif;
  src: local("lato"), url("../font/Lato-Regular.ttf") format("ttf");
}
@font-face {
  font-family: lato_bold, sans-serif;
  src: local("lato_bold"),
    url("../font/Lato-Bold.ttf") format("ttf");
}
@font-face {
  font-family: lato_black, sans-serif;
  src: local("lato_black"),
    url("../font/Lato-Black.ttf") format("ttf");
}
body {
  margin: 0;
  font-family: Nunito !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
