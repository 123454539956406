@import "../../assets/scss/variables";
.hero {
  width: 100%;

    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

  .hero_img{
    height: 100%;
    width: 100%;
    padding-left: 0;
  }
  &::after {
    content: "";
    display: none;
    width: 103%;
    height: 211px;
    background-color: #1b2c63;
    position: absolute;
    bottom: -110px;
    left: 0;
    transform: rotate(5deg);
  }
  .hero_info {

    height: 600px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .hero_image {
      height: 685px;
      margin: 0 auto;
      display: block;
    }
    .text_holder {

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .text {
        font-size: 65px;
        font-weight: bold;

        line-height: 1.4;
        display: flex;
        height: 180px;
        .hashtag {
          color: $colorOrange;
          margin-right: 25px;
          font-size: 80px;
          width: 40px;
        }
        .colored {

          color: #bb3729;
        }
      }
      .partner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;

        .text {
          color: #fff;
          font-size: 20px;
          margin: 0;
          font-weight: normal;
        }
        .fa_logo {
          margin-left: 10px;
          width: 210px;
        }
      }
    }
  }
}
.left_hero_holder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.sub_hero_text {
  color: #fff;
  text-align: left;
  font-size: 18px;
  color:gray;
}
.partner {

  color: #fff;
  width: 400px;
  z-index: 9;
  margin-top: 30px;
  .text {
    margin-bottom: 0;
    font-size: 14px;
    color: #333;
  }
  img {
    width: 200px;
  }
}

.event_banner{
  z-index: 9;
  background-color: $colorOrange;
  width: 300px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 107%;
  transform: translate(-50%,0);
  border-radius: 20px;

  padding:10px 20px;
  height: 125px;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0,0,0 , 0.16), 0 3px 6px rgba(0,0,0,0.23);
  .text{
    font-weight: bold;
    font-size: 18px;
  }
  .link{
    border: 1px solid #fff;
    padding: 5px 7px;
    border-radius:5px;
    cursor: pointer;
    color:#fff;
    &:hover{
      border-color: $colorBlue;
      color: $colorBlue;
    }
  }
}

.abstract_animation_holder{
  width: 100%;
  height: 600px;
  position: relative;
  &:hover{
    .ani_element{
      &.whirpool{
        transform: rotate(360deg) scale(1.3);
        top:80px;
      }
      &.colorfulTriangleBlue{
        transform: translate(-50%,-50%) rotate(90deg);
        left: 55%;
      }
      &.oval2{
        width: 300px;
        right: 140px;
        top: 90px;
        transform: scale(0.9);
      }
      &.colorfulTriangleRed{
        width: 50px;
        right: 290px;
        top: 50px;
        transform: translate(-50%,-50%) rotate(20deg) scale(1.6);
      }
      &.colorfulTriangleGray{
        width: 90px;
        right: 30px;
        top: 200px;
        transform: rotate(360deg) scale(0.5);
      }
      &.squareBlue{
        width: 200px;
        right: 80px;
        top: 150px;
        transform: scale(0.7) rotate(90deg);
      }
      &.rhombusRed{
        width: 120px;
        right: 190px;
        top: 350px;
        position: absolute;
        transform: rotate(130deg);
      }
      &.squareRed{
        left: 100px;
        top: 270px;
        transform: rotate(-30deg);
      }
      &.oval{
        left: 200px;
        bottom: 70px;
        transform: scale(0.8);
      }
      &.rhombusBlue{
       transform: scale(1.4);
      }
    }
    
  }
  .ani_element{
    width: 100px;
    max-width: 100%;
    height: auto;
    transition: all 0.3s cubic-bezier(.04,.83,.63,.56);
    &.honeycomb{
      width: 230px;
      right: 0;
      bottom: 100px;
      opacity: 0.8;
      position: absolute;
      transform: rotate(70deg);
    }
    &.whirpool{
      width: 250px;
      left: 90px;
      top: 40px;
      position: absolute;
      
    }
    &.fence{
      width: 180px;
      left: 180px;
      bottom: 50px;
      position: absolute;
      opacity: 0.4;
    }
    &.colorfulTriangleBlue{
      width: 230px;
      top: 50%;
      left:52%;
      transform: translate(-50%,-50%) rotate(90deg);
      position: absolute;
      z-index: 9;
    }
    &.colorfulTriangleRed{
      width: 50px;
      right: 250px;
      top: 150px;
      transform: translate(-50%,-50%) rotate(45deg);
      position: absolute;
    }
    &.colorfulTriangleGray{
      width: 90px;
      right: 30px;
      top: 30px;
      position: absolute;
      transform: rotate(22deg);
    }
    &.rhombusBlue{
      width: 90px;
      left: 120px;
      bottom: 100px;
      position: absolute;
    }
    &.rhombusRed{
      width: 120px;
      right: 260px;
      top: 310px;
      position: absolute;
      transform: rotate(32deg);
    }
    &.squareBlue{
      width: 200px;
      right: 80px;
      top: 150px;
      position: absolute;
    }
    &.squareRed{
      width: 150px;
      left: 150px;
      top: 270px;
      position: absolute;
      transform: rotate(30deg);
    }
    &.oval{
      width: 200px;
      left: 180px;
      bottom: 100px;
      position: absolute;
      z-index: -1;
    }
    &.oval2{
      width: 300px;
      right: 120px;
      top: 50px;
      position: absolute;
      z-index: -1;
    }
  }
}


@media only screen and (min-device-width: 1600px) and (max-device-width: 1699px) {
  .hero .hero_img{
    right: 55px;
    width: 500px;
  }
  .hero .hero_info{
    left: 13px;
  }
  .partner{
    left:90px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 73px;
  }
}
@media only screen and (min-device-width: 1500px) and (max-device-width: 1599px) {
  .hero .hero_img{
    right: 55px;
    width: 500px;
  }
  .hero .hero_info{
    left: 13px;
  }
  .partner{
    left:90px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 73px;
  }
}

@media only screen and (min-device-width: 1400px) and (max-device-width: 1499px) {

  .hero .hero_img{
    right: 55px;
    width: 480px;
  }
  .hero .hero_info{
    left: 13px;
  }
  .partner{
    left:90px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 65px;
  }
}

@media only screen and (min-device-width: 1300px) and (max-device-width: 1399px) {
  .event_banner{
    top:103%;
  }

  .hero .hero_img{
    right: 55px;
    width: 430px;
  }
  .hero .hero_info{
    left: 13px;
  }
  .partner{
    left:90px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 65px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-device-width: 1200px) and (max-device-width: 1299px) {
  .event_banner{
    top:103%;
  }

  

  .hero .hero_img{
    right: 45px;
    width: 370px;
  }
  .hero .hero_info{
    left: 13px;
  }
  .partner{
    left:90px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 60px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
 

  .hero .hero_info .text_holder .partner .fa_logo {
    width: 200px;
  }
  .partner{
    left: 50%;
    transform: translate(-50%,0);
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 55px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 60px;
  }
  .hero .hero_info .text_holder{
    align-items: center;
  }
  .hero .hero_img{
    width: 100%;
    height: 100%!important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto;
    opacity: 0.2;
  }
  .sub_hero_text{
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .hero .hero_info{
    top:50%;
    left:0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .abstract_animation_holder{
    height: 330px;
    margin-bottom: 50px;
  }
  .abstract_animation_holder .ani_element.whirpool{
    width: 170px;
    left: 190px;
    top: 40px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleBlue{
    width: 145px;
    top: 55%;
    left: 52%;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleRed{
    width: 35px;
    right: 250px;
    top: 120px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleGray{
    width: 60px;
    right: 145px;
    top: 30px;
  }
  .abstract_animation_holder .ani_element.rhombusBlue{
    width: 55px;
    left: 120px;
    bottom: 30px;
  }
  .abstract_animation_holder .ani_element.rhombusRed{
    width: 90px;
    right: 305px;
    top: 220px;
  }
  .abstract_animation_holder .ani_element.squareBlue{
    width: 135px;
    right: 115px;
    top: 160px;
  }
  .abstract_animation_holder .ani_element.squareRed{
    width: 83px;
    left: 195px;
    top: 190px;
  }
  .abstract_animation_holder .ani_element.oval{
    width: 135px;
    left: 125px;
    bottom: 2px;
  }
  .abstract_animation_holder .ani_element.oval2{
    width: 190px;
    right: 120px;
    top: 37px;
  }
  .abstract_animation_holder:hover .ani_element.rhombusRed{
    top: 225px;
    right: 240px;
  }
  .abstract_animation_holder:hover .ani_element.squareBlue{
    transform: scale(0.4) rotate(90deg);
  }
  .abstract_animation_holder:hover .ani_element.squareRed{
    left: 200px;
    top: 260px;
  }
  .abstract_animation_holder:hover .ani_element.colorfulTriangleGray{
    right: 140px;
    top: 60px;
  }
  .abstract_animation_holder:hover .ani_element.oval2{
    width: 265px;
    right: 45px;
    top: 20px;
  }
  .hero .hero_img{
    width: 450px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: auto;
    opacity: 0.3;
  }
  .sub_hero_text{
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .hero .hero_info{
    display: block;
    height: auto;
  }
  .hero .hero_info .hero_image {
    display: none;
  }
  .hero .hero_info .text_holder .partner {
    justify-content: center;
    margin-top: 80px;
  }
  .hero {
    // background-color: #1b2c63;
    // height: 500px;
  }
  .hero .hero_info .text_holder .partner .text {
    font-size: 16px;
  }
  .hero .hero_info .text_holder .partner .fa_logo {
    width: 160px;
  }
  .hero .hero_info .text_holder {
    padding-left: 0px;
    margin-top: 0;
    width: auto;
    padding: 0 20px;
    align-items: center;
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 65px;
  }
  .hero .hero_info .text_holder .text {
    font-size: 50px;
    text-align: center;
    height: auto;
  }
  .hero .hero_info .text_holder .text .hashtag{
    font-size: 60px;
    width: 45px;
  }
  .partner{
    bottom: 30px;
    text-align: right;
    width: 100%;
  }
  
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .abstract_animation_holder{
    height: 330px;
    margin-bottom: 50px;
  }
  .abstract_animation_holder .ani_element.whirpool{
    width: 170px;
    left: 40px;
    top: 40px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleBlue{
    width: 120px;
    top: 55%;
    left: 44%;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleRed{
    width: 35px;
    right: 170px;
    top: 120px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleGray{
    width: 60px;
    right: 55px;
    top: 30px;
  }
  .abstract_animation_holder .ani_element.rhombusBlue{
    width: 55px;
    left: 45px;
    bottom: 0;
  }
  .abstract_animation_holder .ani_element.rhombusRed{
    width: 80px;
    right: 335px;
    top: 215px;
  }
  .abstract_animation_holder .ani_element.squareBlue{
    width: 110px;
    right: 95px;
    top: 180px;
  }
  .abstract_animation_holder .ani_element.squareRed{
    width: 70px;
    left: 220px;
    top: 230px;
  }
  .abstract_animation_holder .ani_element.oval{
    width: 120px;
    left: 110px;
    bottom: 20px;
  }
  .abstract_animation_holder .ani_element.oval2{
    width: 175px;
    right: 105px;
    top: 50px;
  }
  .abstract_animation_holder:hover .ani_element.rhombusRed{
    top: 225px;
    right: 240px;
  }
  .abstract_animation_holder:hover .ani_element.squareBlue{
    transform: scale(0.4) rotate(90deg);
  }
  .abstract_animation_holder:hover .ani_element.squareRed{
    left: 200px;
    top: 260px;
  }
  .abstract_animation_holder:hover .ani_element.colorfulTriangleGray{
    right: 140px;
    top: 60px;
  }
  .abstract_animation_holder:hover .ani_element.oval2{
    width: 265px;
    right: 45px;
    top: 20px;
  }
  .hero .hero_info .hero_image {
    display: none;
  }
  .hero .hero_info .text_holder .partner {
    justify-content: center;
    margin-top: 80px;
  }
  .hero {
    // background-color: #1b2c63;
    // height: 465px;
    height: auto;
    display: block;
  }
  .hero .hero_info .text_holder .partner .text {
    font-size: 16px;
  }
  .hero .hero_info .text_holder .partner .fa_logo {
    width: 160px;
  }
  .hero .hero_info .text_holder {
    padding-left: 0px;
    margin-top: 0;
    width: auto;
    margin-bottom: 80px;
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 55px;
  }

  .hero .hero_info .text_holder .text{
    font-size: 35px;
  }
  .hero .hero_info .text_holder .text .hashtag{
    font-size: 50px;
    width: 33px;
  }
  .hero::after{
    height: 165px;
  }
  .partner{
    bottom: 40px;
  }


  
  .hero .hero_info .text_holder .partner .fa_logo {
    width: 200px;
  }
  .partner{
    text-align: right;
    width: 100%;
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 55px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 40px;
    height: auto;

  }
  .hero .hero_info .text_holder{
    align-items: center;
    margin-bottom: 0px;
  }
  .hero .hero_img{
    width: 100%;
    height: 100%!important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto;
    opacity: 0.2;
  }
  .sub_hero_text{
    width: 100%;
    text-align: center;
    margin-left: 0;
    font-size: 18px;
    line-height: 1.1;
  }
  .hero .hero_info{
    top:50%;
    left:0;
  }

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .abstract_animation_holder{
    height: 300px;
    margin-bottom: 50px;
  }
  .abstract_animation_holder .ani_element.whirpool{
    width: 115px;
    left: 0px;
    top: 0px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleBlue{
    width: 105px;
    top: 34%;
    left: 38%;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleRed{
    width: 35px;
    right: 99px;
    top: 115px;
  }
  .abstract_animation_holder .ani_element.colorfulTriangleGray{
    width: 50px;
    right: 23px;
    top: 10px;
  }
  .abstract_animation_holder .ani_element.rhombusBlue{
    width: 43px;
    left: 13px;
    bottom: 19px;
  }
  .abstract_animation_holder .ani_element.rhombusRed{
    width: 65px;
    right: 210px;
    top: 199px;
  }
  .abstract_animation_holder .ani_element.squareBlue{
    width: 90px;
    right: 60px;
    top: 135px;
  }
  .abstract_animation_holder .ani_element.squareRed{
    width: 60px;
    left: 150px;
    top: 220px;
  }
  .abstract_animation_holder .ani_element.oval{
    width: 105px;
    left: 45px;
    bottom: 45px;
  }
  .abstract_animation_holder .ani_element.oval2{
    width: 148px;
    right: 51px;
    top: 30px;
  }
  .abstract_animation_holder:hover .ani_element.rhombusRed{
    top: 137px;
    right: 137px;
    transform: scale(0.4)
  }
  .abstract_animation_holder:hover .ani_element.squareBlue{
    transform: scale(0.4) rotate(90deg);
    right: 10px;
  }
  .abstract_animation_holder:hover .ani_element.squareRed{
    left: 80px;
    top: 215px;
  }
  .abstract_animation_holder:hover .ani_element.colorfulTriangleGray{
    right: 87px;
    top: 100px;
  }
  .abstract_animation_holder:hover .ani_element.oval2{
    width: 170px;
    right: 90px;
    top: 0px;
  }
  .abstract_animation_holder:hover .ani_element.colorfulTriangleRed{
    width: 40px;
    right: 35px;
    top: 40px;

  }
  .event_banner{
    display: none;
  }
  .hero .hero_info {
    top: 50%;
  }
  .hero .hero_info .hero_image {
    display: none;
  }
  .hero .hero_info .text_holder .partner {
    justify-content: center;
    margin-top: 80px;
  }
  .hero {
    // background-color: #1b2c63;
    // height: 325px;
  }
  .hero .hero_info .text_holder .partner .text {
    font-size: 13px;
  }
  .hero .hero_info .text_holder .partner .fa_logo {
    width: 90px;
  }
  .hero .hero_info .text_holder {
    padding-left: 0px;
    margin-top: 0;
    width: auto;
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 32px;
  }
  .hero .hero_info .text_holder .text {
    font-size: 22px;
    height: 50px;
  }
  .hero .hero_info .text_holder .text .hashtag{
    font-size: 33px;
    width: 24px;
  }
  .sub_hero_text{
    width: 85%;
    font-size: 16px;
    line-height: 1.2;
  }
  .partner{
    bottom: 40px;
    img{
      width: 130px;
    }
    .text{
      font-size: 14px;
    }
  }
  .hero::after{
    height: 100px;
    bottom: -24px;
  }
  .hero .hero_info .text_holder .partner .fa_logo {
    width: 200px;
  }
  .partner{
    left: 50%;
    transform: translate(-50%,0);
  }
  .hero .hero_info .text_holder .text .colored {
    font-size: 55px;
  }
  .hero .hero_info .text_holder .text{
    font-size: 25px;
  }
  .hero .hero_info .text_holder{
    align-items: center;
  }
  .hero .hero_img{
    width: 100%;
    height: 100%!important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto;
    opacity: 0.2;
  }
  .sub_hero_text{
    width: 100%;
    text-align: center;
    margin-left: 0;
    font-size: 16px;
    line-height: 1.1;
  }
  .hero .hero_info{
    height: auto;
  }


}
