@import "../../assets/scss/_variables";
/* KEYFRAMES */

  @keyframes configure-clockwise {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes configure-xclockwise {
    0% {
      transform: rotate(45deg);
    }
    25% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(-135deg);
    }
    75% {
      transform: rotate(-225deg);
    }
    100% {
      transform: rotate(-315deg);
    }
  }
  

.loader_holder{
    position: absolute;
    top:0;
    left:0;
    background-color: #333;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0.9;
    border-radius: 20px;
  }
.spinner_box {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .configure_border_1 {
    width: 115px;
    height: 115px;
    padding: 3px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff331f;
    animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
  }
  
  .configure_border_2 {
    width: 115px;
    height: 115px;
    padding: 3px;
    left: -115px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4cb3;
    transform: rotate(45deg);
    animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
  }
  
  .configure_core {
    width: 100%;
    height: 100%;
    background-color: #1d2630;
  }
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}
