@import "../../assets/scss/_variables";
.page{
  background-color: #f3f3f3;
}
.hero_image_holder {
  position: relative;
  .filter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    bottom: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 30px);
    background-color: rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .image {
    height: 650px;
    width: 100%;
    object-fit: cover;
    margin:0 auto;
    display: block;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    color: #fff;
    .date_share {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      .date {
        font-size: 18px;
      }
      
    }
  }
  .title_holder {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 40px 0 80px 0;
    text-align: center;
    .title {
      color: #fff;
      font-size: 60px;
      font-weight: bold;
    }
    .subtitle{
      color:#fff;
      opacity: 0.8;
      font-style: italic;
    }
  }
}
.post_body {
  padding: 50px 0;
  .sub_title {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 20px;
    text-align: center;
    &.fixed_width{
      text-align: center;
      width: 600px;
      margin: 0 auto 15px auto;    
    }
  }
  .text {
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    .links {
      color: red;
      font-weight: bold;
      position: relative;
      &:hover {
        text-decoration: none;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        height: 1px;
        background-color: red;
      }
    }
  }
  .quote {
    padding-left: 30px;
    text-align: center;
    margin: 40px 0;
    font-size: 30px;
    line-height: 1.2;
    font-style: italic;
    position: relative;
    &:before {
      content: "";
      width: 8px;
      height: 100%;
      display: block;
      background-color: $colorOrange;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .body_image{
    max-height: 500px;
    // width: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 5px;
  }
}
.back_link_holder {
  padding: 40px;
  text-align: center;
  .back_link {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: #1b2c63;
      .icon {
        margin-right: 20px;
      }
    }
    .icon {
      margin-right: 10px;
      transition: all ease-in-out 0.4s;
      font-size: 22px;
      font-weight: bold;
    }
  }
  
}
.wraper{
  width: 60%;
  margin: 0 auto;
}
.body_image_holder{
  text-align: center;
  margin-bottom: 30px;
  &.multiple{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 60%;
    margin: 20px auto;
    .body_image{
      max-height: 100%;
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.list{
  padding-left: 30px;
  .items{
    list-style: disc;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1500px) and (max-width: 1599.98px) {
  .hero_image_holder .title_holder{
    padding: 15px 0 20px 0;
  }
  .hero_image_holder .title_holder .title{
    font-size: 50px;
  }
  .hero_image_holder .image{
    height: 620px;
  }
}
@media (min-width: 1400px) and (max-width: 1499.98px) {
  .hero_image_holder .title_holder{
    padding: 15px 0 20px 0;
  }
  .hero_image_holder .title_holder .title{
    font-size: 50px;
  }
  .hero_image_holder .image{
    height: 635px;
  }
}
@media (min-width: 1300px) and (max-width: 1399.98px) {
  .hero_image_holder .title_holder{
    padding: 15px 0 20px 0;
  }
  .hero_image_holder .title_holder .title{
    font-size: 50px;
  }
  .hero_image_holder .image{
    height: 545px;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px) {
  .hero_image_holder .title_holder{
    padding: 15px 0 20px 0;
  }
  .hero_image_holder .title_holder .title{
    font-size: 50px;
  }
  .hero_image_holder .image{
    height: 550px;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .wraper{
    width: 100%;
  }
  .hero_image_holder .image{
    height: 360px;
  }
  .back_link_holder .back_link {
    font-size: 20px;
  }
  .post_body .quote {
    font-size: 20px;
    padding-left: 30px;
  }
  .post_body .sub_title {
    font-size: 22px;
    line-height: 26px;
  }
  .post_body .text{
    font-size: 16px;
  }
  .hero_image_holder .image{
    height: 465px;
  }
  .hero_image_holder .title_holder {
    padding: 30px 0 30px 0;
  }
  .hero_image_holder .title_holder .title {
    font-size: 40px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .body_image_holder.multiple{
    height: 220px;
  }
  .hero_image_holder .title_holder .subtitle{
    font-size: 16px;
  }
  .post_body .body_image{
    max-height: 400px;
  }
  .post_body{
    padding: 20px 0;
  }
  .wraper{
    width: 100%;
  }
  .hero_image_holder .info .date_share .date{
    font-size: 16px;
  }
  .back_link_holder .back_link {
    font-size: 20px;
  }
  .post_body .quote {
    font-size: 20px;
    padding-left: 60px;
    line-height: 37px;
    text-align: left;
  }
  .post_body .text{
    font-size: 16px;
  }
  .post_body .sub_title {
    font-size: 22px;
    line-height: 25px;
  }
  .hero_image_holder .image{
    height: 345px;
  }
  .hero_image_holder .title_holder {
    padding: 25px 0 20px 0;
  }
  .hero_image_holder .title_holder .title {
    font-size: 32px;
  }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .body_image_holder.multiple{
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    .body_image{
      max-height: 500px;
      margin: 10px 0;
    }
  }
  .hero_image_holder .title_holder .subtitle{
    font-size: 16px;
  }
  .hero_image_holder .info .date_share{
    padding: 0 10px;
  }
  .hero_image_holder .info{
    padding: 10px 10px;
  }
  .wraper{
    width: 100%;
  }
  .post_body{
    padding: 30px 0;
  }
  .hero_image_holder .image {
    height: 330px;
  }
  .back_link_holder .back_link {
    font-size: 20px;
  }
  .post_body .quote {
    font-size: 22px;
    padding-left: 60px;
    line-height: 37px;
    text-align: left;
  }
  .post_body .sub_title {
    font-size: 20px;
    line-height: 32px;
  }
  .hero_image_holder .title_holder {
    padding: 10px 0 10px 0;
  }
  .hero_image_holder .title_holder .title {
    font-size: 25px;
  }
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .body_image_holder.multiple{
    flex-direction: column;
    width: 100%;
    height: auto;
  }
  .post_body .sub_title.fixed_width{
    width: 100%;
  }
  .hero_image_holder .title_holder .subtitle{
    font-size: 16px;
  }
  .hero_image_holder .info{
    padding: 10px 5px;
  }
  .hero_image_holder .info .date_share{
    padding: 0 10px;
  }
  .wraper{
    width: 100%;
  }
  .post_body{
    padding: 30px 0;
  }
  .hero_image_holder .info .date_share .date {
    font-size: 16px;
  }
  .post_body .text {
    font-size: 18px;
  }
  .hero_image_holder .image {
    height: auto;
  }
  .back_link_holder .back_link {
    font-size: 18px;
  }
  .post_body .quote {
    font-size: 20px;
    padding-left: 30px;
    line-height: 30px;
    text-align: left;
  }
  .post_body .sub_title {
    font-size: 18px;
    line-height: 27px;
  }
  .hero_image_holder .title_holder {
    padding: 10px 0 10px 0;
  }
  .hero_image_holder .title_holder .title {
    font-size: 23px;
  }
}
