@import "../../../assets/scss/_variables";
.mobile_menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 112vh;
  background-color: #fff;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.3s;
  .list {
    .items {
      text-align: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .links {
        color: $colorBlue;
        font-weight: bold;
        font-size: 40px;
      }
    }
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .mobile_menu .list .items {
    margin-bottom: 10px;
  }
  .mobile_menu .list .items .links {
    font-size: 30px;
  }
}
