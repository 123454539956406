@import "../../assets/scss/_variables";
.page{
    padding: 100px 0;
}
.title{
    font-size: 45px;
    margin-bottom: 70px;
    font-weight: bold;
}
.text{
    font-size: 18px;
    a{
        color:$colorOrange;
    }
}
.subtitle{
    margin-bottom: 20px;
    margin-top: 40px;
}
.list{
    list-style: disc;
    padding-left: 30px;
    margin-bottom: 30px!important;
    .items{
        font-size: 18px;
        margin-bottom: 10px;
    }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .page{
        padding: 50px 0;
    }
    .title{
        font-size: 35px;
        margin-bottom: 40px;
    }
    .subtitle{
        font-size: 28px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .page{
        padding: 50px 0;
    }
    .text{
        font-size: 16px;
    }
    .list{
        .items{
            font-size: 16px;
        }
    }
    .title{
        font-size: 30px;
        margin-bottom: 40px;
    }
    .subtitle{
        font-size: 22px;
    }

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .page{
        padding: 30px 0;
    }
    .text{
        font-size: 16px;
    }
    .list{
        .items{
            font-size: 16px;
        }
    }
    .title{
        font-size: 25px;
        margin-bottom: 40px;
    }
    .subtitle{
        font-size: 20px;
    }
}
