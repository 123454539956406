@import "../../assets/scss/variables";
.card {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
  height: 350px;
  margin-bottom: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &:hover {
    .main_info {
      .read_more {
        opacity: 1;
        margin-top: 30px;
      }
    }
  }
  .filter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 10px;
  }
  .top_info {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 5px;
    .date {
      color: #fff;
      letter-spacing: 1px;
    }
    .share {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 5px;
      z-index: 9;
      cursor: pointer;
      .icon {
        color: #fff;
        font-size: 18px;
        &.font {
          font-size: 23px;
        }
      }
      .share_buttons_holder {
        position: absolute;
        right: 100%;
        display: flex;
        align-items: center;
        border-radius: 30px;
        z-index: 9;
        width: 0;
        opacity: 0;
        overflow: hidden;
        .share_button {
          .social_icons {
            width: 28px;
            margin-right: 10px;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .main_info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    .title {
      font-size: 35px;
      font-weight: bold;
      color: #fff;
    }
    .read_more {
      color: #fff;
      font-size: 18px;
      margin-top: 0;
      opacity: 0;
      width: 100%;
      text-align: center;
      display: block;
      transition: all ease-in-out 0.2s;
      &:hover {
        .icon {
          margin-left: 10px;
        }
      }
      .icon {
        transition: all ease-in-out 0.4s;
      }
    }
  }
}
.subtitle{
  color:#ddd;
  font-style: italic;
  font-size: 20px;
}
@media (min-device-width: 1600px) and (max-device-width: 1699.98px) {
  .card{
    height: 380px;
  }
  .card .main_info .title{
    font-size: 30px;
  }
}

@media (min-device-width: 1500px) and (max-device-width: 1599.98px) {
  .card{
    height: 410px;
  }
  .card .main_info .title{
    font-size: 30px;
  }
}

@media (min-device-width: 1400px) and (max-device-width: 1499.98px) {
  .subtitle{
    font-size: 17px;
  }
  .card{
    height: 330px;
  }
  .card .main_info .title{
    font-size: 25px;
  }
}

@media (min-device-width: 1200px) and (max-device-width: 1399.98px) {
  .subtitle{
    font-size: 18px;
  }
  .card {
    height: 320px;
  }
  .card .main_info .title {
    font-size: 23px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card .top_info .share .share_buttons_holder .share_button .social_icons {
    width: 26px;
    margin-right: 5px;
  }
  .card .top_info .share .icon.font {
    font-size: 14px;
  }
  .card .top_info .share .icon {
    font-size: 14px;
  }
  .card .top_info .share {
    width: 30px;
    height: 30px;
  }
  .card {
    height: 330px;
  }
  .card .main_info .title {
    font-size: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .subtitle{
    font-size: 18px;
  }
  .card {
    height: 350px;
  }
  .card .main_info .title {
    font-size: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card {
    height: 350px;
  }
  .card .main_info .title {
    font-size: 25px;
  }
}

@media (max-width: 575.98px) {
  .subtitle{
    font-size: 16px;
  }
  .card {
    height: 300px;
    margin: auto;
    margin-bottom: 20px;
  }
  .card .top_info{
    padding: 7px 10px;
  }
  .card .top_info .date{
    font-size: 13px;
  }
  .card .top_info .share{
    width: 30px;
    height: 30px;
    .icon{
      font-size: 14px;
    }
  }
  .card .main_info {
    padding: 8px;
  }
  .card .main_info .title {
    font-size: 20px;
  }
}
