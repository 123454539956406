@import "../../../assets/scss/variables";
.hire_holder {
  width: 100%;
  height: 700px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(27, 44, 99, 0.8);
  }
  .image {
    max-height: 100%;
    border-radius: 20px;
    object-fit: cover;
    border: 2px solid #fff;
  }
  .text_holder {

    text-align: right;
    .title {
      color: #333;
      font-weight: bold;
      font-size: 85px;
    }
    .text {
      color: #333;
      font-size: 25px;
      line-height: 1.2;
      margin-bottom: 80px;
      margin-top: 30px;
    }
    .button {
      border: 1px solid $colorOrange;
      background-color: $colorOrange;
      &:hover{
        background-color: #cc0000;
        border-color: #cc0000;
      }
    }
  }
}
@media (min-device-width: 1400px) and (max-device-width: 1499.98px) {
  .hire_holder .text_holder .text {
    font-size: 20px;
  }
  .hire_holder .text_holder .title {
    font-size: 70px;
  }
  .hire_holder{
    height: 560px;
  }
}
@media (min-device-width: 1300px) and (max-device-width: 1399.98px) {
  .hire_holder{
    height: 565px;
  }
  .hire_holder .text_holder .text {
    font-size: 20px;
  }
  .hire_holder .text_holder .title {
    font-size: 62px;
  }
}
@media (min-device-width: 1200px) and (max-device-width: 1299.98px) {
  .hire_holder{
    height: 565px;
  }
  .hire_holder .text_holder .text {
    font-size: 20px;
  }
  .hire_holder .text_holder .title {
    font-size: 62px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hire_holder {
    height: auto;
  }
  .hire_holder .text_holder {
    width: 60%;
    padding: 30px 30px 30px 100px;
  }
  .hire_holder .text_holder .text {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .hire_holder .text_holder .title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hire_holder .text_holder .button{
    height: 47px;
    min-width: 175px;
    font-size: 18px;
  }
  .hire_holder .text_holder .title {
    font-size: 35px;
  }
  .hire_holder .text_holder .text {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .hire_holder .text_holder {
    width: 100%;
    text-align: center;
  }
  .hire_holder .image {
    width: 100%;
  }
  .hire_holder {
    height: auto;
    padding: 30px 0 90px 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hire_holder .text_holder .title {
    font-size: 55px;
  }
  .hire_holder .text_holder .text {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .hire_holder .text_holder {
    width: 100%;
    text-align: center;
    padding: 30px 30px 30px 30px;
  }
  .hire_holder .image {
    width: 100%;
  }
  .hire_holder {
    height: auto;
  }
}

@media (max-width: 575.98px) {
  .hire_holder .text_holder .button{
    height: 47px; 
  }
  .hire_holder .text_holder .title {
    font-size: 30px;
  }
  .hire_holder .text_holder .text {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .hire_holder .text_holder {
    width: 100%;
    text-align: center;
    padding: 0px 15px 0px 15px;
  }
  .hire_holder .image {
    width: 100%;
    margin-bottom: 30px;
  }
  .hire_holder {
    height: auto;
    padding: 0 0 60px 0;
  }
}
