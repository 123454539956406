@import "../../assets/scss/variables";
.header {
  // border-bottom: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px 0;
  
  .logo_holder {
    height: 100%;
    display: flex;
    align-items: center;
    .logo {
    }
  }

  .navigation {
    .list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .items {
        margin-left: 20px;
        padding: 30px 0;
        .links {
          color: #333;
          text-transform: uppercase;
          font-weight: bold;
          color: $colorBlue;
          position: relative;
          font-size: 14px;
          &:after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0;
            display: block;
            width: 0;
            height: 2px;
            background-color: $colorOrange;
            margin: 6px auto 0 auto;
            transition: all ease-in 0.2s;
          }
          &:hover {
            text-decoration: none;
            &:after {
              width: 100%;
            }
          }
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.burger_holder {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2px;
  z-index: 999999;
  position: relative;
  display: none;
}
.burger_button_holder {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #1b2c63;
  margin: 6px 0;
  transition: 0.4s;
}
.bar3 {
  margin-bottom: 0;
}
.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}
@media (min-width: 1200px) and (max-width: 1599.98) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .burger_holder {
    display: flex;
  }
  .header {
    padding: 10px 0;
  }
  .header .navigation .list {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .burger_holder {
    display: flex;
  }
  .header .navigation .list {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .burger_holder {
    display: flex;
  }
  .header .navigation .list {
    display: none;
  }
}
