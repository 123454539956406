@import "../../assets/scss/variables";
.home_under_hero {
  // background-image: url("../../assets/images/backg2.svg");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  background-image: url("../../assets/images/backg2.svg");
  background-position: 24px -2px;
  background-repeat: no-repeat;
  background-size: cover;
}
.event_holder{
  padding: 0 40px;
}
.event{
  width: 100%;
  box-shadow: 0 3px 6px rgba(0,0,0 , 0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #f7f7f7;
  margin-top: 300px;
  position: relative;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 60% 40%;
  .text_holder{
    padding: 20px 40px 20px 30px;
    .title{
      font-size: 25px;
      font-weight: bold;
      color: $colorBlue;
    }
    .location{
      font-size: 18px;
      opacity: 0.5;
      font-style: italic;
      font-weight: bold;

    }
    .text{
      font-size: 18px;
      margin-bottom: 7px;
      .red{
        color:$colorOrange;
      }
    }
    .buttons_holder{
      margin:20px 0;
      a{
        font-size: 16px;
        margin-right: 20px;
        height: 35px;
        min-width: 145px;
        padding: 9px 16px 4px;
      }
    }
    .list{
      .items{
        .red{
          color: $colorOrange;
        }
      }
    }
  }
  .image{
    // width: 600px;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-radius: 20px;
    padding: 5px;
  }
}
@media (min-width: 1200px) and (max-width: 1599.98) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .event{
    grid-template-columns: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .event{
    grid-template-columns: 100%;
  }
}

@media (max-width: 575.98px) {
  .event_holder{
    padding: 0 10px;
  }
  .event{
    grid-template-columns: 100%;
    margin-top: 120px;
    .text_holder{
      padding: 15px 15px 15px 15px;
      .title{
        font-size: 20px;
      }
      .text{
        font-size: 16px;
        line-height: 1.4;
      }
      .buttons_holder{
        text-align: center;
        a{
          margin: 0;
          margin-right: 10px;
          margin-bottom: 20px;

        }
      }
    }
  }
}
