@import "../../assets/scss/_variables";
.title_holder {
    width: 100%;
    // height: 410px;
    // background-image: url("../../assets/images/team/team2.webp");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 150px 0;
    .head_image{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      // object-fit: cover;
      // height: 100%;
    }
    .filter {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(27, 44, 99, 0.69);
    }
    .title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      text-transform: uppercase;
      text-align: left;
      display: inline-block;
      color: #fff;
      span {
        color: $colorOrange;
      }
    }
  }
  .body{
      padding: 150px 150px;
      .slider_images_holder{
         margin: 0 20px;
         border-radius: 10px;
        .slider_image{
          max-width: 550px;
          height: auto;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .gall_holder{
        div{
     
          place-content:unset!important;
        }
      }
  }
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .body{
    padding: 100px 0;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .body{
    padding: 80px 0;
  }
  .title_holder{
    padding: 110px 0;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .title_holder .head_image{
    object-fit: cover;
    height: 100%;
  }
  .title_holder{
    padding: 70px 0;
  }
  .body{
    padding: 50px 0;
  }
  .title_holder .title{
    display: block;
    text-align: center;
    font-size: 32px;
    left: unset;
    top: unset;
    transform: translate(0,0);    
    position: relative;
  }
}
