@import "../../assets/scss/_variables";

.share {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    .share_buttons_holder {
      position: absolute;
      right: 100%;
      display: flex;
      align-items: center;
      border-radius: 30px;
      z-index: 9;
      width: 0;
      opacity: 0;
      overflow: hidden;
      .share_button {
        .social_icons {
          width: 35px;
          margin-right: 10px;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}
