@import "../../assets/scss/_variables";
.cookies_text {
    position: fixed;
    bottom: 0;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 20px;
    z-index: 9;
    .text {
      margin-bottom: 0;
    }
    .hr{
      margin: 30px 0;
      border-top: 1px solid #fff;
      opacity: 0.3;
    }
    .read_more_holder{
      width: 100%;
      margin-bottom: 30px;
      .list{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .items{
          &:first-child{
            margin: 0 20px 0 40px;
          }
          a{
            font-weight: bold;
            color: #fff;
            text-decoration: underline;
            &:hover{
              color:$colorOrange
            }
          }
        }
      }
    }
    .buttons_holder{
      display: flex;
      align-items: center;
      justify-content: center;
      .button {
        cursor: pointer;
  
        font-weight: bold;
        background-color: $colorOrange;
        font-size: 14px;
        padding: 10px 17px;
        &:hover{
          background-color: #cc0000;
          border-color: #cc0000;
        }
      }
      .preferences_btn{
        font-size: 14px;
        padding: 10px 17px;
        cursor: pointer;
  
        font-weight: bold;
        background-color: lightgrey;
        color: #333;
        margin-left: 20px;
        &:hover{
          background-color: #c1c1c1;
        }
      }
    }
   
}




@media (min-width: 1400px) and (max-width: 1499.98px){
    .cookies_text{
        width: 37%;
    }
}
@media (min-width: 1300px) and (max-width: 1399.98px){
    .cookies_text{
        width: 37%;
    }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px){
    .cookies_text{
        width: 40%;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .cookies_text{
        width: 100%;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .cookies_text{
        width: 100%;
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
 
    .cookies_text .buttons_holder .preferences_btn{
        padding: 10px 7px;
        font-size: 12px;
    }
    .cookies_text .buttons_holder .button{
        padding: 10px 7px;
        font-size: 12px;
    }
    .cookies_text .read_more_holder .list .items:first-child{
        margin: 0 15px 0 20px;
    }
    .cookies_text{
        width: 100%;
    }
}
