@import "../../assets/scss/_variables";
.page {
  .body {
    padding: 100px 0 100px 0;
    .list {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
      border: 1px solid $colorGray;
      padding: 20px;
      border-radius: 20px;
      background-color: $colorGray;
      box-shadow: 0px 2px 5px 0px #8b8b8b;
      .item {
        color: $colorBlue;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        text-transform: uppercase;
        &:last-child {
          margin-bottom: 0;
        }
        .check {
          width: 30px;
          margin-right: 15px;
        }
        span {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
.title_holder {
  width: 100%;
  // height: 350px;
  // background-image: url("../../assets/images/Flyeralarm_Header.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 150px 0;
  .head_image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
  }
  .filter {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 44, 99, 0.69);
  }
  .title {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-transform: uppercase;
    text-align: left;
    display: inline-block;
    color: #fff;
    span {
      color: $colorOrange;
    }
  }
}
.body {
  padding: 30px 0;
  .text {
    font-size: 18px;
    line-height: 1.7;
    .red {
      color: $colorOrange;
    }
    .gray {
      color: #666666;
      font-weight: 600;
    }
  }
}
.image1 {
  margin-bottom: 100px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  float: right;
  box-shadow: 4px 3px 5px 1px #8b8b8b;
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .title_holder{
    padding: 130px 0;
  }
  .image1{
    width: 190px;
    height: 190px;
  }
  .title_holder .title{
    font-size: 45px;
    &::after{
      top:55px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .title_holder{
    padding: 105px 0;
  }
  .image1{
    display: block;
    float: none;
    width: 240px;
    height: 240px;
    margin: 0  auto 30px auto;
  }
  .title_holder .title{
    font-size: 40px;
    &::after{
      top:55px;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .title_holder .title{
    font-size: 30px;
    &::after{
      top:55px;
    }
  }
  .title_holder{
    padding: 40px 0;
  }
  .page .body{
    padding: 50px 0;
  }
  .image1{
    display: block;
    float: none;
    width: 240px;
    height: 240px;
    margin: 0  auto 30px auto;
  }
  .title_holder .title{
    font-size: 35px;
    text-align: center;
    position: relative;
    transform: translate(0,0);
    display: block;
    left: unset;
    top: unset;
    &::after{
      top:45px;
    }
  }
}
