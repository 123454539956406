@import "../../../assets/scss/variables";
.news_holder {
  padding: 100px 0;
}
.button_holder {
  padding: 50px 0;
  text-align: center;
}
@media (min-width: 1200px) and (max-width: 1599.98) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .news_holder {
    padding: 50px 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .news_holder {
    padding: 50px 0;
  }
}

@media (max-width: 575.98px) {
  .main_title{
    margin-bottom: 50px;
  }
  .news_holder {
    padding: 30px 0;
  }
}
