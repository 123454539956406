@import "../../assets/scss/_variables";
.page {
  // padding: 150px 0 150px 0;
  position: relative;
  overflow: hidden;

  // background-color: #1b2c63;s
  .bckg_bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
  }
}
.title_holder {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  .intro_text{
    color: $colorFont;
    font-size: 50px;
    line-height: 1;
    display: block;
    margin-top: 165px;
    font-weight: bold;
  }
  .apply_links{
    &:hover{
      text-decoration: none;
    }
  }
  .apply{
    color: $colorBlue;
    font-weight: bold;
    font-size: 18px;
    margin-top: 50px;
    width: 100%;
    display: block;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      .icon {
        margin-left: 10px;
      }
    }
    .icon {
      transition: all ease-in-out 0.4s;
    }
  }
  .into_image{
    width: 435px;
    float: right;
    margin-top: 25px;
  }
  .title {
    text-transform: uppercase;
    text-align: left;
    display: inline-block;
    color: $colorBlue;
    color: #fff;
    margin-bottom: 0;
    span {
      color: $colorOrange;
    }
  }
}
.body {
  margin: 50px 0 0 0;
  .default_text_holder{
    background-color: $colorGray;
    .default_text{
      margin-bottom: 50px;
      font-size: 22px;
      width: 650px;
      display: block;
      margin: 70px auto 70px auto;
      text-align: center;
      .partner_button{
        margin-top: 30px;
      }
      .firstLetter{
        color: $colorOrange;
        text-decoration: none;
        font-weight: bold;
        font-style: normal;
        font-size: 30px;
      }
    }
    
  }
  
  .sections{
    padding: 50px 0;
   
    &:last-child{
      background-color: $colorGray;
      .text{
        color: $colorFont;
        &.blue_bckg{
          .firstLetter{
            color: $colorOrange;
            text-decoration: none;
            font-weight: normal;
            font-style: normal;
          }
          span{
            font-weight: bold;
          }
        }
      }
      .image{
        margin-bottom: 70px;
      }
    }
  }
  .image{
    border-radius: 20px;
    width: 100%;

  }
  .text {
    font-size: 18px;
    color: $colorFont;
    line-height: 1.3;
    &.first_par{
      margin-bottom: 70px;
    }
    .firstLetter{
      font-size: 30px;
      font-weight: bold;
    }
    span {
      color: $colorOrange;
    }
  }
}
.logos_holder{
  .list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .tech_title{
      margin-right: 10px;
      margin-bottom: 10px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      border: 1px solid #000;
      padding: 1px 10px;
      min-width: 87px;
      text-align: center;
      &:last-child{
        border: none;
      }
    }
    .items{
      background-color: rgba($color: #fff, $alpha: 1);
      border: 1px solid #eee;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      padding: 5px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo_img{
        width: 80%;
      }
      &:hover{
        .logo_img{
          filter: grayscale(0);
        }
      }
    }
    .and_more{
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}

.width_adjuster{
  width: 1100px;
  margin: 0 auto;
}
@media (min-width: 1300px) and (max-width:1399.98px){
  .body .text.first_par{
    margin-bottom: 10px;
  }
  .body .sections{
    padding: 50px 0;
    &:first-child{
      padding: 0 0 50px 0;
    }
  }
  .logos_holder .list .items{
    width: 80px;
    height: 80px;
    margin-right: 10px;
    .logo_img{
      width: 70%;
    }
  }
  .title_holder{
    height: 575px;
  }
  .title_holder .into_image{
    width: 380px;
    margin-top: 0;
  }
  .title_holder .intro_text{
    font-size: 44px;
    margin-top: 90px;
  }
  .logos_holder .list .tech_title{
    font-size: 12px;
    min-width: 65px;
  }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width:1299.98px){
  .logos_holder .list .tech_title{
    font-size: 12px;
    min-width: 65px;
  }
  .logos_holder .list .items{
    width: 80px;
    height: 80px;
    margin-right: 10px;
    .logo_img{
      width: 70%;
    }
  }
  .title_holder{
    height: 575px;
  }
  .title_holder .into_image{
    width: 390px;
    margin-top: 0;
  }
  .title_holder .intro_text{
    font-size: 44px;
    margin-top: 80px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .title_holder .intro_text{
    font-size: 35px;
  }
  .width_adjuster{
    width: 100%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .width_adjuster{
    width: 100%;
  }
  .body .default_text_holder .default_text{
    font-size: 20px;
    margin: 30px auto 30px auto;
  }
  .body .sections:last-child .image{
    margin-bottom: 0;
  }
  .body .sections{
    padding: 50px 0;
    &:first-child{
      padding: 50px 0 50px 0;
    }
  }
  .logos_holder{
    padding: 30px 0 20px 0;
  }
  .logos_holder .list .items{
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-bottom: 10px;
    .logo_img{
      width: 70%;
    }
  }
  .title_holder{
    padding: 20px 0;
    height: auto;
    margin-top: 40px;
  }
  .title_holder .intro_text{
    margin: 0;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    display: block;
    font-size: 28px;
  }
  .title_holder .into_image{
    float: none;
    width: 325px;
    margin: 0 auto;
    margin-top:30px;
    display: block;
  }
  .apply_links{
    text-align: center;
    margin-top: 30px;
  }
  .body .image{
    margin-bottom: 20px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .body .sections:last-child .image{
    margin-bottom: 0;
  }
  .body .sections{
    padding: 50px 0;
    &:first-child{
      padding: 40px 0 40px 0;
    }
  }
  .logos_holder{
    padding: 30px 0 20px 0;
  }
  .logos_holder .list .items{
    width: 70px;
    height: 70px;
    margin-right: 10px;
    margin-bottom: 10px;
    .logo_img{
      width: 70%;
    }
  }
  .title_holder{
    padding: 40px 0;
    height: auto;
  }
  .title_holder .intro_text{
    margin: 0;
    margin-bottom: 0;
    position: relative;
    font-size: 28px;
    text-align: center;
    display: block;
  }
  .title_holder .into_image{
    float: none;
    width: 325px;
    margin: 0 auto;
    margin-top: 50px;
    display: block;
  }
  .apply_links{
    text-align: center;
  }
  .body .image{
    margin-bottom: 20px;
  }
  .body .default_text_holder .default_text{
    width: 100%;
    font-size: 18px;
    margin: 30px 0 30px 0;
  }
  .width_adjuster{
    width:100%
  }
  .title_holder{
    margin-top: 40px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .body .default_text_holder .default_text{
    width: 100%;
    font-size: 18px;
    margin: 30px 0 30px 0;
  }
  .width_adjuster{
    width:100%
  }
  .title_holder{
    margin-top: 40px;
  }
  .apply_links{
    text-align: center;
  }
  .body .image{
    margin-bottom: 20px;
  }
  .body .sections:last-child .image{
    margin-bottom: 0;
  }
  .body .sections{
    padding: 50px 0;
    &:first-child{
      padding: 40px 0 40px 0;
    }
  }
  .logos_holder{
    padding: 30px 0 20px 0;
  }
  .logos_holder .list .items{
    width: 56px;
    height: 56px;
    margin-right: 10px;
    margin-bottom: 10px;
    .logo_img{
      width: 90%;
    }
  }
  .title_holder{
    padding: 0;
    height: auto;
  }
  .title_holder .intro_text{
    margin: 0;
    margin-bottom: 0;
    position: relative;
    font-size: 20px;
    text-align: center;
    display: block;
  }
  .title_holder .into_image{
    float: none;
    width: 220px;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
  }
  .title_holder .apply{
    margin-top: 20px;
    font-size: 14px;
  }
}
